import $ from 'jquery';
// import Swiper from 'swiper';
import Swiper from 'swiper/bundle';
import 'swiper/css';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import * as THREE from 'three';
require('./simpleform.js');

// gsap scrollTrigerr
gsap.registerPlugin(ScrollTrigger);


const isSmartPhone = () => {
  if (window.matchMedia && window.matchMedia('(max-device-width: 768px)').matches) {
    return true;
  } else {
    return false;
  }
};

/**
 * ç¾åœ¨ä¸€ã‹ã‚‰ä¸Šã‚¹ã‚¯ãƒ­ãƒ¼ãƒ«ã§ã‚¯ãƒ©ã‚¹ãµã‚ˆ
 */
class ScrollDownToggleClass {
  constructor(target, border = 56) {
    this.target = target;
    this.border = border;
    this.offset = 0;
    this.lastPosition = 0;
    this.ticking = false;
  }
  toggleClass() {
    this.lastPosition = window.scrollY;
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        if (this.lastPosition > this.border) {
          if (this.lastPosition > this.offset) {
            this.target.classList.add('is-hide');
          } else {
            this.target.classList.remove('is-hide');
          }
          this.offset = this.lastPosition;
        }
        this.ticking = false;
      });
      this.ticking = true;
    }
  }
}
/**
 * 
 * @param {*} min 
 * @param {*} max 
 * @returns ãƒ©ãƒ³ãƒ€ãƒ æ•°å€¤
 */
const getRandom = (min, max) => {
  const delta = max - min;
  return (direction = 1) => (min + delta * Math.random()) * direction;
}

function rotate(target, direction) {

  gsap.to(target, randomTime2(), {
    rotation: randomAngle(direction),
    // delay: randomDelay(),
    // ease: Sine.easeInOut,
    onComplete: rotate,
    onCompleteParams: [target, direction * -1]
  });
}

function moveX(target, direction) {

  gsap.to(target, randomTime(), {
    x: randomX(direction),
    // ease: Sine.easeInOut,
    onComplete: moveX,
    onCompleteParams: [target, direction * -1]
  });
}

function moveY(target, direction) {
  gsap.to(target, randomTime(), {
    y: randomY(direction),
    // ease: Sine.easeInOut,
    onComplete: moveY,
    onCompleteParams: [target, direction * -1]
  });
}




const randomRectFadeIn1 = (c, tOrign = "right") => {
  c.forEach((element) => {
    gsap.set(element, {
      scaleX: 0,
      transformOrigin: tOrign,
    });
    gsap.to(element, {
      duration: getRandom(.5, 2.5)(),
      delay: getRandom(.5, 2.5)(),
      ease: "power2.out",
      scaleX: 1,
    });
  });
}
const randomRectFadeIn2 = (c, tOrign = "right", target = "") => {
  c.forEach((element) => {
    gsap.set(element, {
      scaleX: 0,
      transformOrigin: tOrign,
    });
    gsap.to(element, {
      duration: getRandom(.5, 2.5)(),
      delay: getRandom(.5, 2.5)(),
      ease: "power2.out",
      scaleX: 1,
      scrollTrigger: {
        trigger: target,
        start: 'top bottom',
      }
    });
  });
}

/**
 * @desc ã‚¹ã‚¯ãƒ­ãƒ¼ãƒ«ã§ã‚ã‚‹ãƒã‚¤ãƒ³ãƒˆã‚’è¶…ãˆãŸã‚‰ã‚¯ãƒ©ã‚¹ã‚’ä»˜ä¸Ž
 * @param {*} targetEl  
 * @param {*} togglePointEl 
 * @param {*} toggleClass 
 */
const scrollInToggleClass = (targetEl, togglePointEl, toggleClass) => {
  if (togglePointEl) {
    const observer1 = new IntersectionObserver((entries) => {
      for (const e of entries) {
        if (e.isIntersecting) {
          targetEl.classList.remove(toggleClass);
        } else {
          targetEl.classList.add(toggleClass);
        }
      }
    });
    observer1.observe(togglePointEl);
  } else {
    if (targetEl) {
      targetEl.classList.add(toggleClass);
    }
  }
};
/**
 * @desc ä¸€æ–‡å­—ã¥ã¤æ–‡å­—ã‚’ãƒ©ãƒƒãƒ—ã™ã‚‹
 * @param {*} target 
 * @returns ä¸€æ–‡å­—ã¤ã¥spanã§ãƒ©ãƒƒãƒ—ã—ãŸãƒ†ã‚­ã‚¹ãƒˆ
 */
const spanWrapText = (target) => {
  const nodes = [...target.childNodes]; // ãƒŽãƒ¼ãƒ‰ãƒªã‚¹ãƒˆã‚’é…åˆ—ã«ã™ã‚‹
  let returnText = ''; // æœ€çµ‚çš„ã«è¿”ã™ãƒ†ã‚­ã‚¹ãƒˆ

  for (const node of nodes) {
    if (node.nodeType == 3) {
      //ãƒ†ã‚­ã‚¹ãƒˆã®å ´åˆ
      const text = node.textContent.replace(/\r?\n/g, ''); //ãƒ†ã‚­ã‚¹ãƒˆã‹ã‚‰æ”¹è¡Œã‚³ãƒ¼ãƒ‰å‰Šé™¤
      const splitText = text.split(''); // ä¸€æ–‡å­—ãšã¤åˆ†å‰²
      for (const char of splitText) {
        returnText += `<span>${char}</span>`; // spanã‚¿ã‚°ã§æŒŸã‚“ã§é€£çµ
      }
    } else {
      returnText += node.outerHTML;
    }
  }
  return returnText;
};


const jsTitles = document.querySelectorAll(".js-title");
if (jsTitles) {
  jsTitles.forEach((e) => {
    const mainText = e;
    mainText.innerHTML = spanWrapText(mainText)
    const spans = mainText.querySelectorAll('span');
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: e,
        start: 'top 90%',
      }
    });
    tl.from(spans, {
      opacity: 0,
      duration: 0.05,
      stagger: 0.08,
    });
  })

}

// randomRectFadeIn1(document.querySelectorAll(".js-rects rect"))
// randomRectFadeIn2(document.querySelectorAll(".js-rects2 rect"), "left", ".js-rects2")
// randomRectFadeIn2(document.querySelectorAll(".js-rects3 rect"), "right", ".js-rects3")
// randomRectFadeIn2(document.querySelectorAll(".js-rects4 rect"), "right", ".js-rects4")
// randomRectFadeIn2(document.querySelectorAll(".js-rects5 rect"), "left", ".js-rects5")


const fadeInEls = gsap.utils.toArray('.js-fadein');
if (fadeInEls) {
  fadeInEls.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 40,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      duration: .5,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const randomX = getRandom(1, 10);
const randomY = getRandom(1, 10);
const randomX2 = getRandom(10, 30);
const randomY2 = getRandom(10, 30);
const randomDelay = getRandom(0, 1);
const randomTime = getRandom(3, 5);
const randomTime2 = getRandom(5, 10);
const randomAngle = getRandom(8, 12);
const randomFloat = (c) => {
  gsap.set(c, {
    x: randomX(-1),
    y: randomX(1),
  });
  moveX(c, 1);
  moveY(c, -1);
}
const randomFloat2 = (c) => {
  gsap.set(c, {
    x: randomX2(-1),
    y: randomX2(1),
  });
  moveX(c, 30);
  moveY(c, -30);
}

// scrollToggleClass
// gsap.utils.toArray('.section').forEach((box, index) => {
//   gsap.to(box, {
//     scrollTrigger: {
//       trigger: box,
//       start: 'top bottom',
//       onEnter: () => box.classList.add('is-active'),
//     }
//   });
// });

// const scrollDownToggleClass = new ScrollDownToggleClass(document.getElementById('header'));

// window.addEventListener('scroll', () => {
//   scrollDownToggleClass.toggleClass()
// });




/****/
const floatDot = gsap.utils.toArray('.js-floatdot')
if (floatDot.length !==0) {
  randomFloat(floatDot)
}

const bgEls = gsap.utils.toArray('.js-bg');
if (bgEls.length !==0) {
  bgEls.forEach((box, index) => {
    randomFloat2(box);
    // gsap.to(box, {
    //   y: "80%",
    //   x: "130%",
    //   scale: 2.5,
    //   scrollTrigger: {
    //     trigger: box,
    //     start: 'top bottom',
    //     end: 'bottom top',
    //     scrub: 1.5,
    //   }
    // });
  });
}
const bgElsAlt = gsap.utils.toArray('.js-bg-alt');
if (bgElsAlt.length !==0) {
  bgElsAlt.forEach((box2, index) => {
    randomFloat2(box2);
    // gsap.to(box2, {
    //   y: "80%",
    //   x: "-180%",
    //   scale: 2.5,
    //   scrollTrigger: {
    //     trigger: box2,
    //     start: 'top bottom',
    //     end: 'bottom top',
    //     scrub: 1.5,
    //   }
    // });
  });
}

// mv animation
if (document.querySelector(".hero")) {
  var mvTl = gsap.timeline()
  const mainText = document.querySelector(".hero__title");
  mainText.innerHTML = spanWrapText(mainText)
  const spans = mainText.querySelectorAll('span');
  gsap.to(".swiper-slide-image", { opacity: 0, y: 30 })
  // gsap.to(".hero__mv-figure", { y: 30})
  // 1
  mvTl.to(".hero__rect1", {
    x: "0",
    delay: .25,
  })
  //2 
  mvTl.from(spans, {
    opacity: 0,
    stagger: 0.08,
    duration: 0.55,
    delay: .25,
  });
  //3
  mvTl.to('.hero__sub-title', {
    opacity: 1,
    delay: .1,
  })
  //4
  mvTl.to(".swiper-slide-image", { opacity: 1, y: 0 })
  // mvTl.to(".hero__mv-figure", { opacity: 1, y: 0})
  mvTl.fromTo(".hero__rect1", { width: "100%" }, {
    width: isSmartPhone() ? "100%" : "29.3vw",
    delay: .5,
    duration: .2,
  })
  mvTl.from(".hero__catch-logo", { opacity: 0 })
  mvTl.from(".header", { opacity: 0 })
  mvTl.from(".hero__circle", {
    opacity: 0, onComplete: function () {
      initSlider1()
    },
  })
  // mvTl.from(document.querySelectorAll(".hero__catch1 span"), {
  //   opacity: 0,
  //   duration: 0.8,
  //   stagger: 0.1,
  // })
}
function initSlider1() {
  const bar = document.querySelector('.js-bar');
  const barDuration = 6000;
  const mySwiper = new Swiper('.js-slider', {
    loop: true,
    autoplay: {
      delay: barDuration,
      disableOnInteraction: false,
    },
    speed: 1000,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    on: {
      init: function () {
      },
      slideChangeTransitionStart: function (result) {
        bar.style.transitionDuration = '0s',
          bar.style.transform = 'scaleX(0)'
      },
      slideChangeTransitionEnd: function (result) {
        bar.style.transitionDuration = barDuration + 'ms',
          bar.style.transform = 'scaleX(1)'
      },
    },
  });
}

if (document.querySelector('.js-alt-visual1')) {
  gsap.set('.js-alt-visual1', {
    position: 'relative',
    zIndex: 4,
  });
  gsap.set('.js-alt-visual2', {
    zIndex: 3,
  });

  const slideTl = gsap.timeline();
  slideTl.to('.js-alt-visual1', {
    y: 70,
    rotation: -15,
    opacity: 0,
    delay: 2,
    zIndex: 2,
  });
  slideTl.to('.js-alt-visual2', {
    delay: 0,
    duration: 0,
    zIndex: 3,
  });
  slideTl.to('.js-alt-visual1', {
    y: 0,
    duration: .2,
    opacity: 1,
    rotation: 0,
  });

  slideTl.to('.js-alt-visual2', {
    y: -50,
    rotation: -15,
    opacity: 0,
    delay: 2,
    duration: .2,
    zIndex: 1,
  });
  slideTl.to('.js-alt-visual1', {
    delay: 0,
    duration: 0,
    zIndex: 3,
  });
  slideTl.to('.js-alt-visual2', {
    y: 0,
    x: 0,
    opacity: 1,
    rotation: 0,
  });
  slideTl.repeat(-1)
}


const $hmbg = $('.header__button');
const $svgHmbgIcon = Snap('#js-hmbg-icon')
const SPEED = 300;
const EASING = mina.easeout;
const normalPath = "M44.6,41.3A4.7,4.7,0,1,1,49.3,46,4.7,4.7,0,0,1,44.6,41.3Zm-18.8,0A4.7,4.7,0,1,1,30.5,46,4.7,4.7,0,0,1,25.8,41.3ZM7,41.3A4.7,4.7,0,1,1,11.7,46,4.7,4.7,0,0,1,7,41.3ZM44.6,22.5a4.7,4.7,0,1,1,4.7,4.7A4.7,4.7,0,0,1,44.6,22.5Zm-18.8,0a4.7,4.7,0,1,1,4.7,4.7A4.7,4.7,0,0,1,25.8,22.5ZM7,22.5a4.7,4.7,0,1,1,4.7,4.7A4.7,4.7,0,0,1,7,22.5ZM44.6,3.7a4.7,4.7,0,1,1,4.7,4.7A4.7,4.7,0,0,1,44.6,3.7Zm-18.8,0a4.7,4.7,0,1,1,4.7,4.7A4.7,4.7,0,0,1,25.8,3.7ZM7,3.7a4.7,4.7,0,1,1,4.7,4.7A4.7,4.7,0,0,1,7,3.7Z";
const activePath = "M44.6,41.3A4.7,4.7,0,1,1,49.3,46,4.7,4.7,0,0,1,44.6,41.3ZM7,41.3A4.7,4.7,0,1,1,11.7,46,4.7,4.7,0,0,1,7,41.3ZM25.8,22.5a4.7,4.7,0,1,1,4.7,4.7A4.7,4.7,0,0,1,25.8,22.5ZM44.6,3.7a4.7,4.7,0,1,1,4.7,4.7A4.7,4.7,0,0,1,44.6,3.7ZM7,3.7a4.7,4.7,0,1,1,4.7,4.7A4.7,4.7,0,0,1,7,3.7Z";

const $drawer = $('.header__drawer')
let isAnimation = false;
let time = 800;
$hmbg.on('click', function () {
  if (isAnimation) {
    return false;
  }
  isAnimation = true;
  const headerTl = gsap.timeline();
  const spans = document.querySelectorAll('.js-bg-rect');
  const spans2 = document.querySelectorAll('.js-bg-rect-main');
  if ($drawer.hasClass('is-active')) {
    $svgHmbgIcon.animate({ d: normalPath }, SPEED, EASING);
    headerTl.to(spans2, {
      opacity: 1,
      duration: 0.04,
      delay: 0,
    })
    headerTl.to('.bg-rect-g', { opacity: 0, delay: 0, })
    headerTl.to(spans, {
      height: "0",
      delay: 0,
      stagger: 0.08,
      // stagger: {
      //   from: "end",
      //   each: 0.02,
      //   duration: 0.04,
      // },
      onComplete: () => {
        setTimeout(function () {
          $drawer.toggleClass('is-active')
          isAnimation = false;
        }, 100)
      }
    })
    gsap.to('.header__drawer-menu', { opacity: 0, delay: 0, })
    gsap.to('.header__drawer-bottom-menu', { opacity: 0, delay: 0, })
    gsap.to('.header__drawer-logo', { opacity: 0, delay: 0 })

  } else {
    // é–‹ã„ãŸæ™‚
    $svgHmbgIcon.animate({ d: activePath }, SPEED, EASING);
    $drawer.toggleClass('is-active')
    headerTl.to(spans, {
      height: "100%",
      stagger: 0.1,
      duration: 0.24,
      delay: 0,
    })
    headerTl.to('.bg-rect-g', { opacity: 1, delay: 0, duration: 0.05, })
    headerTl.to(spans2, { opacity: 0, delay: 0, duration: 0.05, })
    headerTl.to('.header__drawer-logo', { opacity: 1, delay: 0, duration: 0.05, })
    headerTl.to('.header__drawer-menu', { stagger: 0.08, opacity: 1, delay: 0, duration: 0.18, })
    headerTl.to('.header__drawer-bottom-menu', {
      stagger: 0.08, opacity: 1, delay: 0, duration: 0.05,
      onComplete: () => {
        isAnimation = false;
      }
    })
  }
})

