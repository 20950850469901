
$.fn.simpleform = function (options) {
    var opts = $.extend({}, $.fn.simpleform.defaults, options);

    if ($('#simpleform-style').length <= 0) {
        var src = '<style type="text/css" id="simpleform-style">';
        src += '.confirm-el { display:none}';
        // src += '.not-confirm-el { display:block}';
        src += '.is-confirm .confirm-el { display:block}';
        src += '.is-confirm .not-confirm-el { display:none}';
        src += '.simple-form-confirm {display: inline-block;margin-right: .5rem;}';
        src += '[data-simpleform-error] {display: none;}';
        src += '</style>';
        $('head').append(src);
    }

    var htmlspecialchars = function (str) {
        return (str + '').replace(/&/g, '&amp;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
    }
    var simpleValidate = function ($form, inputs) {
        var flg = true;
        var $errs = $form.find('.form_error_wrap')
        $errs.css('display', 'none');
        $errs.removeClass('is-error')

        inputs.each(function () {
            var $selfInput = $(this);
            var isEmail = $selfInput.data('simpleform-email');
            var isRequired = $selfInput.data('simpleform-required');
            var regex = $selfInput.data('simpleform-regex')

            // è ¢ï¿½ï¿½åŒ»ãƒ¡ç¹§ï½§ç¹ï¿½ã‘
            var errorTo;
            var isError = false;
            if (isRequired) {
                var _value = $selfInput.val()
                if ($selfInput.is(':checkbox')) {
                    var groups = $selfInput.data("simpleform-group")
                    if (groups) {
                        var _value = false
                        $(groups).each(function () {
                            if ($(this).prop('checked')) {
                                _value = 1;
                            }
                        })
                    } else {
                        var _value = $selfInput.prop('checked')
                    }
                }
                if (_value <= 0 || false) {
                    var errorTo = $selfInput.data('simpleform-error-to');
                    isError = true;
                    flg = false;
                }
            }

            if (isEmail) {
                if (new RegExp('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$').test($selfInput.val()) !== true) {
                    isError = true;
                    flg = false;
                }
            }
            if (regex != undefined) {
                if (new RegExp(regex).test($selfInput.val()) !== true) {
                    isError = true;
                    flg = false;
                }
            }
            if (isError) {
                if (errorTo) {
                    $form.find(errorTo).show().addClass('is-error');
                } else {
                    $selfInput.parent().find('.form_error_wrap').show().addClass('is-error');
                }
            }
        });
        return flg;
    };
    return this.each(function () {
        $('.form_error_wrap').hide();
        $('[data-simpleform-error]').hide();
        var $targetInputElements = $(this).find('input,textarea,select');
        var $form = $(this);
        $(this).find('.is-back').on('click', function () {
            opts.beforeReInput.apply($form, []);

            $('body,html').scrollTop($form.offset().top);
            $form.removeClass('is-confirm');
            $("." + opts.confirmClassName).remove();
            $targetInputElements.each(function () {
                if ($(this).is('select')) { }
                if ($(this).is(':checkbox')) {
                    $(this).parent().find('br').remove()
                    $(this).parent().show();
                } else {
                    $(this).show();
                }
            })
        })

        $targetInputElements.on('change', function () {
            var $errs = $form.find('.form_error_wrap')
            if ($errs.hasClass('is-error')) {
                simpleValidate($form, $targetInputElements);
            }
        });

        $(this).on('submit', function () {

            var $self = $(this);
            if ($self.hasClass('is-confirm')) {
                return true;
            }

            // validate 
            var flg = simpleValidate($form, $targetInputElements);
            var $errorClass = $self.find('.is-error');
            if ($errorClass.length !== 0) {
                $errorClass.each(function (i) {
                    if (i == 0) {
                        var scrollToTarget = $(this).parent();
                        var scrollToValue = scrollToTarget.offset().top;
                        $('body,html').animate({ scrollTop: scrollToValue }, 300, 'swing');
                        return false;
                    }
                })
            }
            var buildDisplayInputValue = function (val) {
                return "<div class=" + opts.confirmClassName + ">" + htmlspecialchars(val) + "</div>";
            };

            if (!flg) {
                return false;
            }
            // confirm
            $self.addClass('is-confirm');
            opts.beforeConfirm.apply($self, []);

            $targetInputElements.each(function () {
                var val = $(this).val();
                var confirmSuffix = $(this).data('confirm-suffix');
                if ($(this).is(':hidden')) {
                    return true;
                }
                if ($(this).is('select')) {
                }
                if ($(this).is(':checkbox')) {
                    $(this).parent().hide();
                    if ($(this).prop('checked')) {
                        $(this).parent().parent().append(buildDisplayInputValue(val))
                    }
                } else if ($(this).is(':radio')) {
                    $(this).parent().hide();
                    if ($(this).prop('checked')) {
                        $(this).parent().parent().append(buildDisplayInputValue(val))
                    }

                } else {
                    $(this).hide();
                    $(this).parent().append(buildDisplayInputValue(val))
                }
                if (confirmSuffix) {
                    $(this).parent().append("<div class=" + opts.confirmClassName + ">" + htmlspecialchars(confirmSuffix) + "</div>");
                }
            })
            if (opts.scrollToTarget) {
                var $confirmScrollToTarget = $(opts.scrollToTarget);
            } else {
                var $confirmScrollToTarget = $(this);
            }
            $('body,html').scrollTop($confirmScrollToTarget.offset().top);
            return false;
        })
    });
};

$.fn.simpleform.defaults = {
    confirmClassName: "simple-form-confirm",
    scrollToTarget: "",
    beforeConfirm: function () {
    },
    beforeReInput: function () {
    },
};

